
import Vue from 'vue'
export default Vue.extend({
    
    data() {
        return {
            valid: true,
            clientName: '',
            clientNameRules: [ (v: string) => !!v || 'Client name is required' ],
            users: [],
            itsm: '',
            itsmRules: [ (v:string) => !!v || 'Please Select ITSM' ],
            nbLicences: 0,
            nbLicencesRules: [
                (v: number) => !!v || 'number of licences needed is required',
                (v:number) => v > 0 || 'more than 0 please'
            ]
        }
    },

    computed: {

        usersList() {
            const users = []
            for(const userId of Object.keys(this.$store.getters['users/users'])) {
                if(userId != this.$store.getters['auth/user'].uid) {
                    users.push({
                        email: userId,
                        enterprise: this.$store.getters['users/users'][userId].enterprise
                    })
                }
            }
            return users
        }

    },

    methods: {
        async save() {
            if(this.$refs.form && (this.$refs.form as Vue & { validate: () => boolean }).validate()) {
                this.$store.commit('projectForm/setClientName', this.clientName)
                this.$store.commit('projectForm/setUsers', this.users)
                this.$store.commit('projectForm/setNbLicences', this.nbLicences)
                this.$store.commit('projectForm/setItsm', this.itsm)

                const saved = await this.$store.dispatch('projectForm/save')
                console.log(saved)
                this.$router.push('/')
            }
        }
    },

    mounted() {
        this.$store.dispatch('projectForm/reset')
    }

})
