import { render, staticRenderFns } from "./CreateProjectView.vue?vue&type=template&id=663a0382&scoped=true&lang=pug&"
import script from "./CreateProjectView.vue?vue&type=script&lang=ts&"
export * from "./CreateProjectView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "663a0382",
  null
  
)

export default component.exports